import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import kpi from "../images/kpi.png";
import ron from "../images/microsoft/ron.png";
import addie from "../images/microsoft/addie.png";
import { Details, Blockquote } from "../components/details.js";
import MoreProjects from "../components/moreProjects.js";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">Microsoft Inventory Management</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <div className="nda">
  <h3>
    This project is under an NDA, thus there are many details removed from this
    case study.
  </h3>
    </div>
    <p>{`All companies, including Microsoft, have marketing assets, otherwise known as swag. Not only can employees request swag, but administrators also need to organize and manage their inventory. As a design and developer intern at Microsoft, I worked with my team to create an internal inventory management platform to help the organization of over 2000 employees perform these basic management actions, helping to streamline the overall workflow by 85%.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bb54c813d498794cb51ddaa4ca06ec1c/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnUlEQVQ4y02TS4sUSRSF6zeMi5lfoMj0uHDna+FA4yAyoGIJboRW0J0OwiAiLQo1QouKgqC46pXQCIoKMjALH71QZunCzcBQ1V3dXZn16Kp8RMaNxycRWbYmXOJmRt4T95xzo5G0Ie140pU6+mHteJK2Z7AKkx6oIVQjyFMYrRH30g5bNV8jYDVCIpXHWY8RF0PEkqXw78f/ePB4kTfvP/LP22Wu3rzFk6W/KVJQpdmqscYj4hisORqDrmNS5GRlgVgT81zlqE149uIds83T/Nac4+e9+/lxZoYrrfvoMaiq4vvHecuoZ2kE1KwoGGcZRVmitVBJhRrBs5fLHGzOcejkGQ4cOcG2Hb9wtXUPmYAxFu89zjmc8xF0MwkdrnlKpSJoiJAbK+gJLD1/y96jp9lz5BSzzTl27PuVv+48ioBVpSmUoigVpapizWbqaAyngJM8J592qI2uKb9c5vezFzh29iLNc3/ww/YZLl1pYTJqJlrHWjEG62wNGDusKsYBsCjjT2KFYgivXn/gwvUF5m8/5NKNBXbPHma+dRe9WQOKEbQI1jqMNTXl0GEx7bCmazHOTDV8z5k/r3FxvsWJufP8tHMXl68tRMpZXjAaj8mn+qtKMU79Nw1jVFV9YnBsw5P2CjobG3xuJ3xup/zf3aCfTBiuE7UbjEaRVeg2Uk6mY1MLq+IoiJhIOe1aUPCpD8eXoPkUFj8BHnorLrosInENToeNLZeDu+GkABj0cFiG645y7Kiso5c5kswx0Q5Rjn7X4f23GQymyFcN01WHUhqtdQQ0xmCM0O9axoNwuuCtxhmNd0IxsSQdi+jaWWttZBdmeLBuaYR7mawY+t0AYki7QrIi8b4mbUu6KiQxTJ13TL3XkfgeItat1t+/AJBArUWwSnB7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Microsoft Inventory Management header image",
            "title": "Microsoft Inventory Management header image",
            "src": "/static/bb54c813d498794cb51ddaa4ca06ec1c/1cfc2/header.png",
            "srcSet": ["/static/bb54c813d498794cb51ddaa4ca06ec1c/3684f/header.png 225w", "/static/bb54c813d498794cb51ddaa4ca06ec1c/fc2a6/header.png 450w", "/static/bb54c813d498794cb51ddaa4ca06ec1c/1cfc2/header.png 900w", "/static/bb54c813d498794cb51ddaa4ca06ec1c/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Context`}</h4>
    <h2>{`Initial problem`}</h2>
    <p>{`As an intern, I stumbled across the problem that catalyzed this project when I was trying to get my hands on some Microsoft swag. When I went to request some, I ran into two problems:`}</p>
    <div className="list">
  <h3>
    1. We had no idea who to contact, and the others we asked did not know
    either.
  </h3>
  <h3>
    2. When we sent an email request, we did not hear back for more than a week.
  </h3>
    </div>
    <p>{`Overall, this was a systemic problem as there was no clear organization for marketing assets among the entire company location. This made it hard for anyone to locate and get access to these assets which are meant to be used.`}</p>
    <p>{`Having discovered the problem, I took it upon myself and my team to create something that would help manage the company and make their lives easier when requesting marketing assets.`}</p>
    <Blockquote text="How might we design an application to reduce wasted time and confusion of employees when requesting physical marketing assets by creating a standardized process for requesters?" mdxType="Blockquote" />
    <p>{`While we started off with this initial problem statement, this was later revised after user testing. `}<AnchorLink to="/microsoft#revised-problem" title="Revised problem" className="link" mdxType="AnchorLink">{`
Jump to learn why.`}</AnchorLink></p>
    <p>{`To measure our success, I set the following key performance indicators:`}</p>
    <div className="list">
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Reduce overall processing times to less than a week</h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Increase employee awareness and utilization of marketing assets</h3>
  </div>
    </div>
    <p>{`Due to the limited timeline of the project, I focused on creating an MVP for the requesting process. After learning some more about the administrator's role in the process, the product's scope expanded to include the management process as well.`}</p>
    <h2>{`My approach`}</h2>
    <div className="row">
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">1.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Research</h3>
      <p>Analyze current process</p>
      <p>Personas</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">2.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Design</h3>
      <p>Ideation</p>
      <p>Med-fi designs</p>
      <p>Hi-fi designs</p>
      <p>Coded product</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">3.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Evaluate</h3>
      <p>User testing</p>
      <p>Revised problem</p>
      <p>Design iterations</p>
      <p>Reflection</p>
    </div>
  </div>
    </div>
    <p>{`As the sole designer and a core leader of the team, I got to plan the project's schedule and phases. This was also my first time working in an environment where I got to set the pace and I learned a lot about the process in creating a product.`}</p>
    <p>{`With the above approach, we designed and developed a inventory management platform for employees to request and manage internal marketing assets. It helped to standardize the overall process and streamline the organization process. `}<AnchorLink to="/microsoft#solution" title="Solution" className="link" mdxType="AnchorLink">{`
Jump to the solution.`}</AnchorLink></p>
    <h4>{`Research`}</h4>
    <h2>{`Analyzing the current process`}</h2>
    <p>{`Having gone through the difficult process of requesting marketing assets myself, I wondered if this was the same experience that all the other employees also went through. After conducting numerous user interviews with employees in the business group divisions, I found the process was very similar to my own experience, validating that something needs to be done about it.`}</p>
    <p>{`From these interviews, I created a consolidated user journey map. While it may seem short, this process typically takes upwards of 10 business days to complete, making it very difficult for employees who need their marketing assets in a hurry to show customers their appreciation.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/7af19/flow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.888888888888886%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzElEQVQY01WQ2Y6EIBAA/f+P3HhkdEXwGE4RrYmQzToPFbq7umlCJRr45kLUF2N9lTjzcE1xT5/j+mJqoJraC70knEms4kR2EAxED+sI6gVOn3hzMvcwD8UFS+7dppJ7A/dd1V1clw0he2Zh6X8Czlt80KjfgOgiyyZZN4XqD9Sw44Im7I6xiczC43eN8y4vqKYW7DvhXWSTibFJmPeONQE1HExdwtuYka+E6lN2Vu+I9mQeD5wJeSa/8P+fCvcC0Zbzj6d71r7ytvR8AP0OfcfWqUHkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "General requesting process",
            "title": "General requesting process",
            "src": "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/1cfc2/flow.png",
            "srcSet": ["/static/bf4e09c0ea514fc10c3edb92fdb0d48c/3684f/flow.png 225w", "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/fc2a6/flow.png 450w", "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/1cfc2/flow.png 900w", "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/21482/flow.png 1350w", "/static/bf4e09c0ea514fc10c3edb92fdb0d48c/7af19/flow.png 1359w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  I created a simplified overview of the current user journey.
    </p>
    <h2>{`Focusing on the user's needs`}</h2>
    <p>{`While conducting over five user interviews, I also summarized my findings into a user persona of a typical requester, including their goals, pain points, and understanding of the overall system. This helped remind us who we were building this product for and also helped us ensure that we address all of their needs with this product.`}</p>
    <p>{`The persona is an employee looking to request marketing assets to present as customer appreciation at their client's closing meeting next week. However, with the current system, the requesting process is confusing and with a tight deadline, users are frustrated at how difficult it is to locate the administrator of the swag they are trying to request from.`}<br />{` `}<br /></p>
    <div className="row">
  <div className="column-3 centre-img no-bottom smaller-right">
    <h3>Requester Ron</h3>
    <p>Owner of a Microsoft business group</p>
    <img src={ron} alt="Requester Ron persona" className="no-border-img half-img cursor " />
  </div>
  <div className="column-6">
    <h3>Goals</h3>
    <p>Convince clients to licence and use Microsoft tools and services</p>
    <br />
    <h3>Pain points</h3>
    <p>Locating administrator of assets</p>
    <p>Trouble negotiating with administrator</p>
    <p>
      Difficulty receiving marketing assets to demonstrate client appreciation
    </p>
  </div>
    </div>
    <br />
    <p>{`Overall, this persona summarized the goals and pain points of individuals who request marketing assets. This helped us empathize with the user and set our team's north star that we aimed towards.`}</p>
    <h4>{`Design`}</h4>
    <h2>{`Ideating as a group`}</h2>
    <p>{`I led a group brainstorming session where we whiteboarded potential ideas. We utilized design techniques like Crazy 8’s and then consolidated all the different ideas into a foundational idea which we all agreed upon and would build off of in our medium fidelity prototype.`}</p>
    <p>{`To further flesh out our idea, we mapped our the user flow. This helped keep the developers informed and contributing to the design process too.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1de5295f5f41e13968de9e315cd12d0b/6bfd0/whiteboard.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFklEQVQ4y2WTW1NaVxiG/YX9A3Wm02lSY/HIKSJyEjYgsGEDGzanDQFRhKBAKOYgKmjEXpg2ppdtPaQ5ENP2uk1Mng7ijJ3pxbPWrG/Wetc73zvfyMX5Z/74Hf58dcNfr4f7oP4/ruv/vX9xPuT9SxgZLC+eHvFst83PvX1eHO7x/KDDj3vbPOu2+Wl/h+ODDse9Ds8Pdq9qR50tek9adFtN9jZbtBsbdFqPeHd2ycjgx3JaQRamKchWSkkH97NuagUfm5Uwj6oxWhWZx7UETxoq3YfL9LbWONwu8/TxKjsPcjSKIWqFGP3TD4wMLBcUGb9tirRko5jysr4SoVlN8X09y2Zzma2HJfZ3a3S3N+h1mxz/0OZov8Vhu8ZOs0RZDZFXIrw9+cDIoB+lrIoi2agWZerlJJWSwupKnEZjmXq9wMZ6jnqtyHq1QLWsUivn2N2s0n6wxv17abJRD/l4iDe//TMU3FgtosoC60mRtZQfNSWSzoRJJERyuShqNkIyFWS5IFNSQ+QyIrVqmqIqsnZPIuY3k5a8vD25FqwU8siilaLkRvGaiUqLxGWBkGihoAaHZIJUVmLkUj6i3nmyoo24aCG8ZCboNqCIrqHDQQ+LahqfS4/itxENWEkl/GTSATzuOcKSg7wksBoSKIRcZMNOcksWkvOzBAQjIa8Js/4WIbeV/sl1KPlUAp9TRyzsRJEFkokA8ZiHRYeWQMBCxmcj5TSR9i4QW9AStGoRBSMm/W1c1mlclgkkt+0mlGw8zpJTT0S0E48KpJIBEsoSft/8lcOE5EKW7EQji/g8czgWJvE4ZjEbx7CZJhAskwRd1qHgwOGSw843o18wfedLdJNfY9TeYv7uGHbrFC6njkWnDpdTi9dtwO+5S0DQ4V2cYUYzyvT4KAv62/gcphvBeCCEdvwrrHoNdsMEduMkVoMG0+wYxplvmZu9g0n3HfN6DSb9BCadBrNxCsPMOHOzGgSLnqjPMxQcjN67s09XY3Nx9omL8xvevxxydT67pH/ykde//s2rX4YMUu2fXtI//Th8f/6ZfwFhtwhj8EX6aAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Ideated requesting flow",
            "title": "Ideated requesting flow",
            "src": "/static/1de5295f5f41e13968de9e315cd12d0b/1cfc2/whiteboard.png",
            "srcSet": ["/static/1de5295f5f41e13968de9e315cd12d0b/3684f/whiteboard.png 225w", "/static/1de5295f5f41e13968de9e315cd12d0b/fc2a6/whiteboard.png 450w", "/static/1de5295f5f41e13968de9e315cd12d0b/1cfc2/whiteboard.png 900w", "/static/1de5295f5f41e13968de9e315cd12d0b/6bfd0/whiteboard.png 1011w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We brainstormed as a group, mapping out the user flow.
    </p>
    <h2>{`Creating a medium fidelity prototype`}</h2>
    <p>{`To consolidate our ideas into a tangible interface, I created medium fidelity mockups in Figma. This was my first time using Figma so there were a number of challenges that I encountered as I was learning on my own. Some of the difficulties included creating reusable components, and creating interactions.`}</p>
    <p>{`The main features include a gallery of selectable items, and writing custom messages to the administrator. This makes it feel natural, similar to an online shopping experience combined with an application form.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5c74fb9397c8636d73ce425f4d431ffb/4917c/medflow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABDklEQVQoz41S2W7EIAzM/39mL23OzQkGH1PZSfpQrdQiWR4wM7IHmv7NMLfA3L2O5R/45g8fhmb8NKgYzBRqCjO78olZ+KypotYa2evMDFGJOxHwxkIQEFZQIdTKQciZICKgUtB23SVkmJcFpZTYj9MEIoKo4kgJlestaCHoh0QFKSX0w4CUM7Z9x9fjgZxzxLKuIbhuG4ZxxHEk7MeBcXpG3ceODvnqsNQahLsTF+36PrDHo20jO/k5Lz/YBZ0fgm6kCqLgvvjy8Xxk98a7Dl/VYgqR09tMFPYEzjnu++OEoC/RCioZld0jOjMT+Mq+Z6m/zs8oJQP+KJ2h6d8Ny6BYR8Xcy4vgP/HN92/zDSRDbdBiXsPBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Medium fidelity mockups and flow",
            "title": "Medium fidelity mockups and flow",
            "src": "/static/5c74fb9397c8636d73ce425f4d431ffb/1cfc2/medflow.png",
            "srcSet": ["/static/5c74fb9397c8636d73ce425f4d431ffb/3684f/medflow.png 225w", "/static/5c74fb9397c8636d73ce425f4d431ffb/fc2a6/medflow.png 450w", "/static/5c74fb9397c8636d73ce425f4d431ffb/1cfc2/medflow.png 900w", "/static/5c74fb9397c8636d73ce425f4d431ffb/21482/medflow.png 1350w", "/static/5c74fb9397c8636d73ce425f4d431ffb/d61c2/medflow.png 1800w", "/static/5c74fb9397c8636d73ce425f4d431ffb/4917c/medflow.png 3152w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  I created Figma mockups for each screen in the flow. Here, they have been
  recreated to omit certain details.
    </p>
    <p>{`The mockups provided a basis for the development team to begin creating the application, while I continued iterating on the initial prototype and user testing.`}</p>
    <h4>{`Evaluate`}</h4>
    <div id="revised-problem"></div>
    <h2>{`Testing with employees`}</h2>
    <p>{`To test this application, I worked with the employees I interviewed earlier for user research. Here, I was able to assess whether their initial concerns were mitigated with this product.`}</p>
    <p>{`Overall, the application addressed all of their goals and pain points, streamlining the requesting process.`}</p>
    <p>{`From these sessions, I learned that I forgot a main stakeholder and potential user of this application: the administrator. This is important because the administrator can also potentially utilize the application to manage the marketing assets and contain the entire requesting process within the application. As a result, these findings definitely called for a revision of the user flows and features.`}</p>
    <p>{`Gratefully, I implored a "test early and often" approach and we were able to catch this oversight in the third week of the project, leaving enough time to iterate and create a more encompassing product.`}</p>
    <h2>{`Revised problem`}</h2>
    <Blockquote text="How might we design an application that will reduce time wasted and confusion when employees are requesting physical marketing assets by standardizing the process for requesters, as well as allow administrators to organize assets and quickly respond to requests?" mdxType="Blockquote" />
    <h2>{`Designing for a two-fold problem`}</h2>
    <p>{`To further understand the role of an administrator in the overall process of reqeusting and managing swag, I interviewed a number of employees responsible for managing the marketing assets in their business group. This helped me understand their day-to-day, responsibilities, and journey when undertaking a request – ultimately, constructing a persona through these learnings.`}</p>
    <br />
    <div className="row">
  <div className="column-3 centre-img no-bottom smaller-right">
    <h3>Admin Addie</h3>
    <p>Manager of marketing assets in their business group</p>
    <br />
    <img src={addie} alt="Admin Addie persona" className="no-border-img half-img cursor " />
  </div>
  <div className="column-6">
    <h3>Goals</h3>
    <p>Complete the jobs they were hired for</p>
    <p>Assist with managing the business group's marketing assets</p>
    <br />
    <h3>Pain points</h3>
    <p>Keeping track of the business group's marketing assets</p>
    <p>Responding to numerous requests of marketing assets</p>
    <p>Managing new order of marketing assets</p>
  </div>
    </div>
    <br />
    <p>{`After understanding the administrator, I created additional medium fidelity mockups and continued user testing to iterate on the prototype and ensure their pain points are addressed and the product helps them to reach their ultimate goals. Thus, the product was extended to encompass both an administrator portal, and a requesting flow.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final designs`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/677aa2638487e51df2c3ed813a86110a/4917c/hiflow.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.888888888888886%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz41Ti27CMAzk/z8TChO0hZQ0aR52bjp3hYGmbaks24l7Pl/aXb9v6A+/2L5hOABjB/SH1YZvfosNZw/samlQBVTerZk1BXKucM6bL0UQY0ItalayWJ0UYOgadqoNQENrT89nXatf0oLjxwmlFIQQcHMOIoKcM0qtUBVrbIDsIiqotaKKIOVscVwW9OOIECNijMil2P7t5nDpB6SUrFZEDZxTGaBURYjBXuIL0/1uxd577LsOd++xLAuUugDGkjXMGft5hjZ9BUw5GX2CjterseNYH+ez7RHcOYdpmgyADWjVxlWThjquIxOQ9FOyQ47IzgS99L3lPCMrgtOfL5fHNG6a3hjyJrmhahci+ow3P8+8iMnylPIKog1zCHDT/RWQwV+LjCkFxd9kKbU8NFwJbQwLv7VmHX8ynkkVeD8jZ4JUhED9xG6YOZlL/dJw6BT/sesJGI9ttVPDcFz3mW81/Fs+AdHWV7FiIsmPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Administrator flow",
            "title": "Administrator flow",
            "src": "/static/677aa2638487e51df2c3ed813a86110a/1cfc2/hiflow.png",
            "srcSet": ["/static/677aa2638487e51df2c3ed813a86110a/3684f/hiflow.png 225w", "/static/677aa2638487e51df2c3ed813a86110a/fc2a6/hiflow.png 450w", "/static/677aa2638487e51df2c3ed813a86110a/1cfc2/hiflow.png 900w", "/static/677aa2638487e51df2c3ed813a86110a/21482/hiflow.png 1350w", "/static/677aa2638487e51df2c3ed813a86110a/d61c2/hiflow.png 1800w", "/static/677aa2638487e51df2c3ed813a86110a/4917c/hiflow.png 3152w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">Administrator flow recreated.</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8d6257fbe7d883ea699d4c5fdd03fc24/4917c/hiflow2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVQoz31Ti46DMAzb/3/mtmMPOMZoaSlt45PTK8e0cZGsVIkxLgmH9igYvwWmB0xf8xbbnsD2wPT43O/OgkN3EkgWlJAPAEQysiQIBEtcYKxBzknrFeQ+roJDexLkVMREoKhRzykljMbA+xnOe5y/Gj3HGPEcR8whKFcFuxMQIx1kJWQR2GlSgXme4b2Hcw79MMAYC2MMur6HsVY5bdcpj/Ei6LxTIUZzueB6vyOmtDqkE7pblgXD84kQgoKirIlIFRSkKKsbYnJOSXSWc1YycwWvmLZ1ya+CdEhBOlDS78ejKOvM97bVF+3Fm0NOjVdjg7me6YDf63q7qfv68BZloBvBMmWsU/5DcRrCgnPTwDmvm1SE3rmrYNlD2d1D7qCdLMIS/uHVPTxy0zPsI8PsgL1pENhhn0PwT/kBtn5cZBdJb80AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Request flow",
            "title": "Request flow",
            "src": "/static/8d6257fbe7d883ea699d4c5fdd03fc24/1cfc2/hiflow2.png",
            "srcSet": ["/static/8d6257fbe7d883ea699d4c5fdd03fc24/3684f/hiflow2.png 225w", "/static/8d6257fbe7d883ea699d4c5fdd03fc24/fc2a6/hiflow2.png 450w", "/static/8d6257fbe7d883ea699d4c5fdd03fc24/1cfc2/hiflow2.png 900w", "/static/8d6257fbe7d883ea699d4c5fdd03fc24/21482/hiflow2.png 1350w", "/static/8d6257fbe7d883ea699d4c5fdd03fc24/d61c2/hiflow2.png 1800w", "/static/8d6257fbe7d883ea699d4c5fdd03fc24/4917c/hiflow2.png 3152w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">Requester flow recreated.</p>
    <p>{`The final application includes features for both administrators and requesters. For administrators, there is a login page to provide secure access to asset and request information including inventory counts, request data visualizations, and an inbox of requests. For requesters, there is a history of requests identified by the user's Microsoft login, as well as a simple request form that simplifies and provides direction for negotiations.`}</p>
    <p>{`I created all of the designs and worked on the frontend of the application as well. I also helped lead the team and scope out the product.`}</p>
    <h2>{`Results`}</h2>
    <p>{`With some final touches on the code and additional user testing, we released the beta version of the application within the office of over 2,000 employees. Upon release, we measured our KPIs and it exceeded our expectations. The final metrics include:`}</p>
    <div className="list">
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Streamlined the overall process, reducing processing times by 85%</h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Engaged employee usage, increasing requests made by 216%</h3>
  </div>
    </div>
    <p>{`To promote our release and encourage onboarding, I designed a series of advertisements that were shown around the office.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3eb40492400438e130c7a4b6a7ff8c69/7ca1f/ad.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44444444444444%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAABXXAAAV1wEELLsZAAACXklEQVQozz2Sz2vTYBjH81948CrILvMgqHhyIApKZQO3OZy6MZ0K6mEKYwdR9uMyPXga4klRhtgJLcPparpBi6Nb2yxd3zTpRpbkTZbmh0neNGnWNNWKifi5Pny+3+eBB+M4ThTFd+8/zMzOzczOPZue+ZxI/mq3WyFBEPi+32w2Dw8PG42G67qO49RDHMfBIIRVhhkYvdcTGzx+8vSRrq7e4VGv4Qbt9u+QIAharZbv+16I67oIIcuyTNPERFHc3a0OjD2I3Xp4of/m0e5TvcMjTc9TVZUgCIqiCILI5/PlEJIkc7kcx3G2bWuahtE0zTB0bHj87JUb56+OdPdcHhq7W7cRQjbP85IkCYIAIWRZVpIkLsQwDNM0a7UaVi6XC4XCtfFHfbcnhu4/OXbm3MW+ftepS5KE43g6naYoKplMEgRRLBaXl5fX19clSZJlGUKIkSSZzWbvTExNzi88nn55oudSbPC6ZZq6rgMASqVStVrd2dlhGIYgiFQqlc1mWZYVRfGvvL29vbm5Of3i1duPnxbevJ6Ympp8+lzT1Kbv8zy/uLi4tra2sbGRSCSWlpbi8bgsy7VaDUIoCAJWBiCXy1Uo8GW/M/+jnVE6oExyPO95nqIoNE3rui7L8sHBAYQwWkEQhH/NFEVtbW0BAIp6J15pkz87JZJg9/fdRgMhBACoVCo0TSuKIsuyqqrRwZqmKYqCgbAZx3HX0mxNdEwVx9N7e3umaRqGgRCK/qFer9shCKFoZBgGVigU8vn8ysrK12+p1RS++h3PZDI8z0fxhmFYlmXbduRH5n/5D6EDDgQ2UugPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Application ad",
            "title": "Application ad",
            "src": "/static/3eb40492400438e130c7a4b6a7ff8c69/1cfc2/ad.png",
            "srcSet": ["/static/3eb40492400438e130c7a4b6a7ff8c69/3684f/ad.png 225w", "/static/3eb40492400438e130c7a4b6a7ff8c69/fc2a6/ad.png 450w", "/static/3eb40492400438e130c7a4b6a7ff8c69/1cfc2/ad.png 900w", "/static/3eb40492400438e130c7a4b6a7ff8c69/21482/ad.png 1350w", "/static/3eb40492400438e130c7a4b6a7ff8c69/d61c2/ad.png 1800w", "/static/3eb40492400438e130c7a4b6a7ff8c69/7ca1f/ad.png 1890w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  The horizontal advertisement I created shown on TVs throughout the building.
  Full link has been removed.
    </p>
    <p>{`In addition, our project team was invited to speak at Microsoft Canada's National Annual General Meeting in September 2019. At the conference, we presented the product in front of 800 employees and received a lot of positive feedback.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`Even though we released the beta version of the platform, there are a few improvements that can be made in the future. With this in mind, we left our project successors with a few items that they may choose to address in future iterations of the application:`}</p>
    <div className="list">
  <h3>
    1. Include a shopping cart approach to allow for multiple items to be
    requested
  </h3>
  <p>
    Currently, there is only the ability to request a single type of item due to
    our time constraints. By allowing more items to be requested at once, users
    are able to streamline their requesting process even more.
  </p>
  <h3>
    2. Allow for the negotiation of a request to be transferred back and forth
  </h3>
  <p>
    An important aspect of the requesting process is for administrators to reply
    back with a counteroffer. While this can be done over email, emails can get
    lost, so an opportunity to improve the app is to open a chat once a request
    has been made.
  </p>
  <h3>
    3. Enable administrators to upload invoices to automatically update
    inventory numbers
  </h3>
  <p>
    To further reduce the amount of manual data entry that happens with managing
    inventory, a further iteration could use AI integrations to recognize order
    receipts and automatically update inventory.
  </p>
    </div>
    <h2>{`Reflecting back`}</h2>
    <p>{`Working on this project at Microsoft was eye-opening. It was my first project with a large team for a large corporation, and I learned so much throughout the two months there. Three of the most important things I learned include:`}</p>
    <div className="list">
  <h3>1. Conducting thorough research before starting</h3>
  <p>
    In this project, we missed a key stakeholder early on, resulting in an
    incomplete scope for the application. Now, I know that I need to conduct
    more user tests and keep an open mind before committing to a solution right
    away.
  </p>
  <h3>
    2. Ontarians were confused by the questions being asked and the redundancy
    of information being asked of them
  </h3>
  <p>
    For the application to become a reality, there must be clear communication
    between designers and developers. This means constantly looping in the
    developers with the process and what is coming down the pipeline.
  </p>
  <h3>3. I can make an impact – even as an intern</h3>
  <p>
    I quickly learned that even as an intern, I can incite positive change
    within an organization as large as Microsoft as long as I have the passion
    and dedication to do so!
  </p>
    </div>
    <p>{`Overall, I am proud of the work that we were able to accomplish as a team, and especially as interns, created something that has an impact on an organization like Microsoft!`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/d0fa6/emilymicrosoft.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.55555555555554%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADvUlEQVQ4yz2T+U/bBRjG+48YE02MY2P0vr5QBEoLiIZwTI6OI4xLBgEli2yZC24qTjPJnOxSHGQcbuOITCVuwEgh47AuHANhXG2xUAqMFZD7+zEdmT88yZvnffPmzfO8j8Q9u8fMqBf7+BrOZ+s4Jv5ldmwNx/gGjokNnM+2WJkHt2OXydEVXDO7eJyw5BT/h8cHh8iiHSQP26wUfmKh9IssMnIs5J0sJDM7h7hjcbxj1HPq09M0/9FObrGF2nsNbADeLZHdHZGtrT02NvdZ34YXy+CeFZHU1NQTnawkKTOcYLMCfYgZpSECuRCKn1yDOSYRc2wC2UUf8ajHxtPJadZ2dtgG1rZ3Wd+Fje1Nnnu2WZwFSUNtIwaTFI0gJTBMiiooFKkyDMEYjyEyBZ0pBZXRgtqUziFdPBpzKv22XtwrXlyrW7i9e/SOjtDXN8iqCyS3fqpDqnkDvUGGJsSPAJWKmxnxlH2fQHx6FkkZxUTEZRIRn010Yj7RSXlcu3qRtl/rsI08oMfWxs+/VNLd9ZgX874LbzehFvxRCn5og97CX6Yk5kwttZVRWI6nkJZVTFJaHunZxWR+WEJ49DFSje+Rm2jB4xlnYWGM6aku7JOzLDlB8ntLG1FRagS9Ar32MCqVktcPa1HKZQRIVQTIdcjVQSjUQcgUOgJkaowyDedPFeJefoprwc7m1hrepf0DU27fvIM58ChxZj1axdvIpDJUSg2BwUZ0ggGVSoNWK6DTCcTGJRBqjEQd+D5Xq24wt2BlYrqHaWcHc3YHHt/b1PxwB/nRNzFo5Wjk/igUSoIMRhKT03g3OgaNWo+gN2AKjyIxORVTRASv+YeTcPJjrtw6TY+tkZm5R7gc8wcLW5taMUceIcQoQyscIswUhCkyErnaH22wAl24HCFShibKD0tuPCWlRfgFhpBzppj7Hd/Q0naJjt5v+XtshGWfhtaOTj5INhBskhMTK/BdRRkVl79GH3KEpPxwjhcbSSkKI78sihvVFygvv0BsYgyFJSc4fzmfryoLuN5QwPDwnwcLR4Zs1LeUcvbLVC5eyqLrYTVVP1bwWXk61fdKuF5fwLW6Ahp/O8ed1rM0NH9O0/1zWB9fwWqrEgfHm5hytouLrmXRY0eUTI5N0We7S/+TZgb+amZo6AGdnc309bbQbW2k23oXa3c9Twba6B9oZXC4HcfMMK5/plldec6Gd1vc2YTVRVj0uewT0hcZH17VSw5eBt3jOOBe8vYD/lXPN+eeQVyY3md+ald0z+6LHgfif7FVA8THYytWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Emily at Microsoft",
            "title": "Emily at Microsoft",
            "src": "/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/1cfc2/emilymicrosoft.png",
            "srcSet": ["/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/3684f/emilymicrosoft.png 225w", "/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/fc2a6/emilymicrosoft.png 450w", "/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/1cfc2/emilymicrosoft.png 900w", "/static/9dbd701cafe0d6f32bbc1c1fb3099b5b/d0fa6/emilymicrosoft.png 1263w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      